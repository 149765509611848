<template lang="pug">
  jumbotron(title="" small)
    template(v-slot:content)
      change-status(:items="activeItems" @needToUpdate="$emit('needToUpdate')" :value="getTabValue")
        template(v-slot:activator="changeStatus")
          app-button(
            color="primary"
            @click.native="changeStatus.toggle"
            :disabled="isDisabled"
            class="change-status-button"
          ) Change status
</template>

<script>
import permsMixin from '@/mixins/perms.mixin'
import appSelect from '@/components/global/fields/appSelect.vue'
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'FeedbackActions',

  mixins: [permsMixin],

  props: {
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    activeItems: {type: Array, required: true}
  },

  computed: {
    isDisabled() {
      return this.loading || this.disabled;
    },

    getTabValue() {
      if (this.$route.query.tab) return this.$route.query.tab
      else return ''
    }
  },

  components: {
    appSelect,
    appButton,
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue'),
    changeStatus: () => import('./actions/ChangeStatus.vue')
  }
}
</script>

<style lang="scss">
.change-status-button {
  text-transform: uppercase;
}
</style>
